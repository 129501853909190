* {
  padding: 0%;
  margin: 0%;
}
.productComponent {
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  min-height: 1500px;
  background-color: white;
  // border: 1px solid #000;
  .productContainer {
    // max-width: 1330px;
    margin: 120px auto;
    // border: 1px solid #000;
    h1 {
      // border: 1px solid #000;
      // left: 153px;
      display: none;
      // top: 52px;
      font-weight: 600;
      font-size: 54px;
      line-height: 56px;
      text-align: center;
      color: #6d35a2;
    }
    .oneProdactResponsive {
      display: none;
    }

    .oneProdact:nth-child(2) {
      .productImage {
        // border: 1px solid #000;
        right: -185px;
        top: -20px;
        position: absolute;
      }
    }
    .oneProdact:nth-child(4) {
      .productImage {
        // border: 1px solid #000;
        right: -245px;
        top: 70px;
        position: absolute;
      }
    }
    .oneProdact:nth-child(3) {
      .productImage {
        // border: 1px solid #000;
        position: absolute;
        left: -267px;
        top: -70px;
      }
    }
    .oneProdact:nth-child(5) {
      .productImage {
        // border: 1px solid #000;
        position: absolute;
        left: -297px;
        top: -100px;
      }
    }
    .oneProdact:nth-child(6) {
      .productImage {
        // border: 1px solid #000;
        right: -305px;
        top: -100px;
        position: absolute;
      }
    }

    .oneProdact:nth-child(even) {
      width: calc(50% + 270px);
      // width: 2000px;
      // left: -947px;
      // border: 1px solid #000;
      position: relative;
      margin-bottom: 140px;
      display: flex;
      // flex-direction: row-reverse;
      height: 273px;
      // border: 1px solid #000;
      .oneProdactIn {
        width: 100%;
        height: 100%;
        // left: -400px;

        background-color: #6d35a2;
        border-radius: 0 50px 50px 0;
        position: relative;
        margin-top: 20px;
      }

      .productAbout {
        position: absolute;

        right: 501px;
        // margin: 0 auto;
        top: 48px;
        h4 {
          font-family: Montserrat;
          font-weight: normal;
          font-size: 16px;

          color: #ffffff;
        }
        h3 {
          font-family: Moniqa;
          margin-top: 2px;
          font-style: normal;
          font-weight: 600;
          font-size: 35px;
          text-transform: uppercase;

          color: #ffffff;
        }
        p {
          font-family: Montserrat;
          margin: 14px 0;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          /* identical to box height */

          color: #ffffff;
        }
      }
    }

    .oneProdact:nth-child(2n + 1) {
      width: calc(50% + 270px);
      // width: 2000px;
      // right: -313px;
      float: right;
      margin-bottom: 140px;

      position: relative;
      display: flex;
      // flex-direction: row-reverse;
      height: 273px;
      // border: 1px solid #000;
      .oneProdactIn {
        width: 100%;
        height: 100%;
        // left: -400px;

        background-color: #6d35a2;
        border-radius: 50px 0 0 50px;
        position: relative;
        margin-top: 20px;
      }

      .productAbout {
        position: absolute;
        // border: 1px solid #000;
        left: 501px;
        top: 48px;
        h4 {
          font-family: Montserrat;
          font-weight: normal;
          font-size: 16px;

          color: #ffffff;
        }
        h3 {
          font-family: Moniqa;
          margin-top: 2px;
          font-style: normal;
          font-weight: 600;
          font-size: 35px;
          text-transform: uppercase;

          color: #ffffff;
        }
        p {
          font-family: Montserrat;
          margin: 14px 0;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;

          color: #ffffff;
        }
      }
    }
  }
}

@media screen and(max-width:950px) {
  .productComponent {
    .productContainer {
      .oneProdact:nth-child(2) {
        .productImage {
          width: 480px;
          // border: 1px solid #000;
          right: -126px;
          top: 30px;
        }
      }
      .oneProdact:nth-child(4) {
        .productImage {
          width: 455px;
          // border: 1px solid #000;
          right: -166px;
          top: 75px;
        }
      }
      .oneProdact:nth-child(6) {
        .productImage {
          width: 511px;
          // border: 1px solid #000;
          right: -186px;
          top: 15px;
        }
      }
      .oneProdact:nth-child(3) {
        .productImage {
          width: 480px;
          // border: 1px solid #000;
          left: -146px;
          // border: 1px solid #000;
          top: 10px;
        }
      }
      .oneProdact:nth-child(5) {
        .productImage {
          width: 511px;
          // border: 1px solid #000;
          left: -186px;
          // border: 1px solid #000;
          top: 10px;
        }
      }
      .oneProdact:nth-child(even) {
        height: 189px;
        // left: -1300px;
        .oneProdactIn {
        }

        .productAbout {
          top: 35px;
          right: 370px;
          h4 {
            font-size: 14px;
          }
          h3 {
            font-size: 28px;
          }
          p {
            font-size: 14px;
            line-height: 10px;
          }
        }
      }
      .oneProdact:nth-child(2n + 1) {
        height: 189px;
        right: -150px;
        // left: -1300px;

        .productAbout {
          left: 370px;
          top: 35px;
          h4 {
            font-size: 14px;
          }
          h3 {
            font-size: 28px;
          }
          p {
            font-size: 14px;
            line-height: 10px;
          }
        }
      }
    }
  }
}
@media screen and(max-width:834px) {
  .productComponent {
    .productContainer {
      h1 {
        display: block;
      }
    }
  }
}
@media screen and(max-width:765px) {
  .productComponent {
    .productContainer {
      .oneProdact:nth-child(2n) {
        display: none;
      }
      .oneProdact:nth-child(2n + 1) {
        display: none;
      }
      .oneProdactResponsive {
        display: block;
        box-shadow: 0px 38px 114px rgba(126, 8, 176, 0.08),
          0px 4.75819px 14.2746px rgba(126, 8, 176, 0.04);
        border-radius: 7px;
        width: 301px;
        // z-index: 1;
        height: 263px;
        // border: 1px solid #000;
        margin: 120px auto;
        position: relative;
        .circle {
          width: 192px;
          top: -96px;
          left: 54px;
          box-shadow: 0px 38px 114px rgba(126, 8, 176, 0.08),
            0px 4.75819px 14.2746px rgba(126, 8, 176, 0.04);
          height: 192px;
          position: absolute;
          background-color: #ffffff;
          z-index: 0;
          border-radius: 50%;
          // border: 1px solid #000;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .mahsulot {
          width: 156px;
          z-index: 10;
          position: absolute;
          left: 0%;
          right: 0;
          margin: 0 auto;
          top: -70px;
        }
        .prodactAll {
          width: 100%;
          height: 100%;
          background-color: #ffffff;
          position: absolute;
          z-index: 4;
          // border: 1px solid #000;
          // box-shadow: 0px 38px 114px rgba(126, 8, 176, 0.08),
          //   0px 4.75819px 14.2746px rgba(126, 8, 176, 0.04);

          .productAbout {
            // border: 1px solid #000;
            position: absolute;
            top: 57px;
            left: 56px;
            h4 {
              font-family: Montserrat;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 17px;

              color: #6d35a2;
            }
            h3 {
              font-family: Moniqa;
              padding: 4px 0 8px;
              font-style: normal;
              font-weight: 600;
              font-size: 28px;
              line-height: 29px;
              text-transform: uppercase;

              color: #6d35a2;
            }
            p {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              /* identical to box height */

              color: #6d35a2;
            }
          }
        }
      }
    }
  }
}
