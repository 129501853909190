@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
* {
  padding: 0%;
  margin: 0%;
}
.header {
  // position: -webk  it-sticky;
  position: fixed;
  width: 100%;
  top: 0px;
  // box-shadow: 0px -7px 60px rgba(0, 0, 0, 0.08),
  //   0px -1.56354px 13.4018px rgba(0, 0, 0, 0.0522314),
  //   0px -0.465507px 3.99006px rgba(253, 55, 55, 0.066);
  z-index: 10;
  // transition: ease 0.4s;
  .responsiveIcon {
    display: none;
  }

  &_menu {
    display: flex;
    height: 81px;
    margin: 0 auto;
    transition: 0.2s;
    // right: 0;
    // left: 0;
    max-width: 1920px;
    // align-items: center;
    justify-content: center;
    // transform: translateX(200%);
    // position: sticky;
    top: 0;
    z-index: 1;
    // background-color: rgb(255, 255, 255);
  }
  .close {
    display: none;
  }

  .menuleft {
    // display: inline-block;
    height: 100%;
    width: 364px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    // position: absolute;
    // border: 1px solid #000;
    // left: 202px;
  }
  .shifobaxshLink {
    display: block;
    text-decoration: none;
    .shifobaxshSut {
      // display: inline-block;
      min-width: 224px;
      // border: 1px solid #6d35a2;
      background-color: #6d35a2;
      height: 81px;
      display: inline-flex;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      // position: absolute;
      h3 {
        color: white;
        font-family: lunchbox;
        font-size: 32px;
      }
    }
  }
  .menuright {
    // display: inline-block;
    // border: 1px solid #000;
    height: 100%;
    width: 364px;
    // right: 286px;
    justify-content: space-around;
    display: flex;
    align-items: center;
    // position: absolute;
  }

  .responsiveNews {
    display: none;
  }
  .responsiveMaqolalar {
    display: none;
  }
  li {
    display: flex;
    font-family: moniqa;
    align-items: center;
    // margin: 15px 45px;
    // padding: 15px 15px;
    font-weight: 600;
    font-size: 17px;
    // flex: none;
    // order: 1;
    justify-content: center;
    // flex-grow: 0;
    position: relative;
    list-style-type: none;
    color: #9d9d9d;
    cursor: pointer;
    // border: 1px solid #000;
    img {
      position: absolute;
      // left: 17px;
      width: 64px;
      // top: 24px;
      // border: 1px solid #000;
      z-index: 1;
      // display: none;
    }

    a {
      text-decoration: none;
      padding: 10px;
      display: inline-block;
      color: #9d9d9d;
    }
    .media {
      width: 111px;
      height: 76px;
      background: #ffffff;
      box-shadow: 0px -22px 36px rgba(16, 104, 7, 0.0367295),
        0px -4.91399px 11.9074px rgba(16, 104, 7, 0.0499631),
        0px -1.46302px 5.89857px rgba(16, 104, 7, 0.12);
      border-radius: 2px;
      position: absolute;
      // left: 900px;
      top: 70px;
      a {
        display: block;
        font-weight: bold;
        font-size: 20px;
        // color: #154a15;
        text-decoration: none;
        text-align: center;
        margin: 11px 0px;
      }
    }
  }
}
.headerMobile {
  display: none;
}

@media screen and (max-width: 834px) {
  .header {
    display: none;
  }
  .headerMobile {
    display: block;
    z-index: 14;
    // background-color: #ffffff;
    position: fixed;
    box-shadow: none;
    top: 0px;
    width: 100%;
    height: 85px;
    // border: 1px solid #000;
    .responsiveIcon {
      display: flex !important;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 80px;
      height: 80px;
      background-color: rgba(109, 53, 162, 0.05);
    }
    &_menu {
      display: block;
      position: absolute;
      background-color: #ffffff;
      // border: 1px solid #000;
      top: 0;
      transition: 0.7s;
      // opacity: 0.5;
      width: 100%;
      // height: 1194px;
      height: 100vh;
      .close {
        display: block;
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        // border: 1px solid #000;
        background: rgba(109, 53, 162, 0.05);
        img {
          position: absolute;
          // left: 0;
        }
      }
      .Ellipse9 {
        position: absolute;
        right: 0;
        top: 0;
      }
      .Ellipse33 {
        position: absolute;
        left: 0;
        bottom: 0;
      }
      .shifobaxshSut {
        display: none;
      }
      .menuAll {
        width: 340px;
        // height: 30%;
        display: flex;
        flex-direction: column;
        position: absolute;
        // border: 1px solid #000;
        // left: 0;
        // right: 150px;
        // margin: 0 auto;
        // right: 0;
        left: 35%;
        top: 90px;
        // margin: 0 auto;
        justify-content: center;
        // left: 315px;
        // text-align: center;
        // align-items: flex-end;
        li {
          display: block;
          // overflow: hidden;
          font-family: Moniqa;
          position: relative;
          font-style: normal;
          font-weight: 600;
          font-size: 64px;
          line-height: 67px;
          color: #9d9d9d;
          /* identical to box height */
          a {
            padding: 0 !important;
            text-decoration: none;
          }
          img {
            left: -235px;
            top: 30px;
            position: absolute;
          }
        }

        .responsiveNews {
          display: block;
        }
        .responsiveMaqolalar {
          display: block;
        }
      }
      p {
        position: absolute;
        // left: calc(50% - 228px / 2);
        // top: calc(50% - 30px / 2 + 535px);
        bottom: 47px;
        width: 100%;
        // margin: 0 auto;
        // left: 303px;
        // right: 0;
        text-align: center;

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 30px;

        color: #6d35a2;
      }
    }
  }
}
@media screen and(max-width:600px) {
  .headerMobile {
    &_menu {
      .menuAll {
        // right: 60px;
        margin: 0 auto;
      }
    }
  }
}

@media screen and(max-width:500px) {
  .headerMobile {
    &_menu {
      .menuAll {
        // right: 40px;
        margin: 0 auto;
        // border: 1px solid #000;
      }
    }
  }
}
@media screen and(max-width:415px) {
  .headerMobile {
    &_menu {
      .menuAll {
        right: 0px;
        left: 0;
        margin: 0 auto;
      }
    }
  }
}

@media screen and(max-width:455px) {
  .headerMobile {
    width: 100%;
    .responsiveIcon {
      width: 52px;
      height: 52px;
    }
    &_menu {
      .close {
        width: 52px;
        height: 52px;
      }
      .menuAll {
        // border: 1px solid #000;
        width: 220px;
        left: 0;
        right: 0;
        // right: 20px;
        margin: 0 auto;
        top: 100px;
        li {
          font-size: 50px;
          img {
            width: 30px;
            left: -50px;
            height: 4px;
            // border: 1px solid #000;
          }
        }
      }
    }
  }
}
