.contact {
  width: 100%;
  background-color: #fbf9fc;

  // height: 900px;
  .contactContainer {
    max-width: 973px;
    // border: 1px solid #000;
    padding: 85px 53px;
    // height: 5000px;
    margin: 0 auto;
    h1 {
      font-weight: bold;
      font-size: 54px;
      display: inline-block;
      font-family: lunchbox;
      color: #6d35a2;
    }
    h5 {
      font-family: LunchBox;
      font-weight: normal;
      font-size: 32px;
      position: relative;
      left: 4vw;
      color: #646464;
    }
    .contactSections {
      display: flex;
      justify-content: space-around;
      margin-top: 30px;
      // border: 1px solid #000;
      .contactSectionsSection {
        width: 300px;
        // border: 1px solid #000;
        text-align: center;
        display: inline-block;
        h3 {
          font-weight: 400;
          font-family: LunchBox;
          font-size: 32px;
          line-height: 33px;
          margin-bottom: 10px;

          color: #646464;
        }
        h2 {
          font-weight: 700;
          font-family: LunchBox;

          font-size: 42px;
          line-height: 44px;
          /* identical to box height */

          color: #6d35a2;
        }
      }
    }
    .contactUsers {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin: 20px auto;
      // margin-bottom: 100px;
      // border: 1px solid black;
      // justify-content: center;
      // align-items: flex-start;
      .contactUsersName {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        // border: 1px solid #000;
        width: 363px;
        height: 126px;
        .contactUsersNameFirstName {
          width: 100%;
          height: 53px;
          position: relative;
          // border: 1px solid #000;
          // margin: 10px;
          input {
            width: 100%;
            outline: none;
            height: 100%;

            border: 2px solid #6d35a2;
            padding-left: 20px;
            font-size: 15px;
            border-radius: 30px;
            box-sizing: border-box;
            &.error {
              border: 2px solid #fc3f3f;
              &::placeholder {
                color: #fc3f3f;
              }
            }
            &::placeholder {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 300;
              font-size: 17px;
              line-height: 21px;
              /* identical to box height */

              color: rgba(109, 53, 162, 0.7);
            }
          }
        }
        .contactUsersNameNumber {
          width: 100%;
          height: 53px;
          position: relative;
          // border: 1px solid #000;
          // margin: 10px;
          input {
            width: 100%;
            outline: none;
            height: 100%;
            border: 2px solid #6d35a2;
            padding: 0% 20px;
            font-size: 15px;
            border-radius: 30px;
            box-sizing: border-box;
            &.error {
              border: 2px solid #fc3f3f;
              &::placeholder {
                color: #fc3f3f;
              }
            }
            &::placeholder {
              font-family: Montserrat;
              font-style: normal;
              font-weight: 300;
              font-size: 17px;
              line-height: 21px;
              /* identical to box height */

              color: rgba(109, 53, 162, 0.7);
            }
          }
        }
      }

      .contactUsersTextarea {
        display: flex;
        align-items: flex-start;
        position: relative;
        // border: 1px solid #000;
        // left: 50px;
        // top: 20px;
        width: 544px;
        height: 126px;

        border-radius: 30px 0px 0px 0px;
        textarea {
          width: 91%;
          height: 100%;
          display: inline-block;
          padding: 17px 23px;
          box-sizing: border-box;
          border: 2px solid #6d35a2;
          border-radius: 30px 0px 0px 0px;
          font-weight: 300;
          font-size: 17px;
          resize: none;

          // padding: 10px 23px;
          outline: none;
          &.error {
            border: 2px solid #fc3f3f;
            &::placeholder {
              color: #fc3f3f;
            }
          }
          &::placeholder {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 300;
            font-size: 17px;
            line-height: 21px;
            /* identical to box height */

            color: rgba(109, 53, 162, 0.7);
            // padding: 17px 23px;
          }
        }
        button {
          // display: inline-block;
          // transform: rotate(90deg);
          width: 46px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 126px;
          cursor: pointer;

          background: #6d35a2;
          border: 2px solid #6d35a2;
          border-radius: 0px 0px 30px 0px;
          transition: 0.2s;
          &:hover {
            background: #3a066b;
            border: 2px solid #3a066b;
          }
          p {
            transform: rotate(-90deg);
            color: white;
            font-family: Lunchbox;
            font-weight: 600;
            font-size: 20px;
            letter-spacing: 1px;
            // flex: none;
            // order: 0;
            // flex-grow: 0;
            // margin: 10px 0px;
          }
        }
      }
    }
    .contactMA {
      display: flex;
      justify-content: space-between;
      .contactMAMessengers {
        display: inline-flex;
        align-items: center;
        max-width: 600px;
        // border: 1px solid #000;
        // margin-right: 26px;
        // margin-left: 9px;
        p {
          display: inline-block;
          font-family: lunchbox;
          font-weight: normal;
          margin-right: 14px;
          font-size: 24px;

          color: #878787;
        }
        div {
          display: inline-block;
          svg {
            path {
              transition: 0.2s;
            }
            cursor: pointer;
            &:not(:last-child) {
              margin-right: 15px;
            }
            &:hover {
              path {
                fill: #c48bfb;
              }
            }
          }
        }
      }
      p {
        display: inline-block;
        // border: 1px solid #000;
        font-family: LunchBox;
        // font-family: Moniqa;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;

        color: #878787;
        span {
          color: #6d35a2;
        }
      }
    }
  }
  iframe {
    border: 1px solid #6d35a2;
    box-sizing: border-box;
    border-radius: 30px 0px;
    margin-top: 37px;
  }
}
@media screen and(max-width:834px) {
  .contact {
    .contactContainer {
      // border: 1px solid #000;
      .contactSections {
        display: flex;
        flex-direction: column;
        align-items: center;
        // border: 1px solid #000;
      }
      .contactSectionsSection {
        // border: 1px solid #000;
        margin-top: 15px;
        h3 {
          margin-bottom: 0;
        }
        h2 {
          margin: 0;
        }
      }
      .contactUsers {
        display: block;
        .contactUsersName {
          width: 100%;
        }
        .contactUsersTextarea {
          width: 100%;
          margin-top: 25px;
          // border: 1px solid #000;
          textarea {
            width: 95%;
          }
        }
      }
      .contactMA {
        display: block;
      }
    }
  }
}
@media screen and(max-width:465px) {
  .contact {
    .contactContainer {
      h1 {
        font-size: 34px;
      }
      .contactSections {
        .contactSectionsSection {
          h3 {
            font-size: 18px;
            line-height: 18px;
          }
          h2 {
            font-size: 28px;
            line-height: 27px;
          }
        }
      }
      .contactUsers {
        .contactUsersTextarea {
          height: 208px;
          display: block;
          // border: 1px solid #000;
          textarea {
            width: 100%;
            height: 168px;
            border-radius: 0 30px 0 0;
          }
          button {
            width: 100%;
            border-radius: 0 0 0 30px;
            position: relative;
            top: -4px;
            height: 40px;
            p {
              transform: rotate(0);
            }
          }
        }
      }
      .contactMA {
        .contactMAMessengers {
          width: unset;
          display: block;
          p {
            display: block;
          }
          div {
            display: flex;
            // border: 1px solid #000;
            height: 62px;
            align-items: center;
            justify-content: space-around;
            // border: 1px solid #000;
          }
        }
      }
    }
  }
}
