.footer {
  //   border: 1px solid #000;
  background: #6d35a2;
  position: relative;
  bottom: 0;
  //   width: 100%;
  height: 60px;

  display: flex;
  align-items: center;
  font-family: montserrat;
  justify-content: space-between;
  &_2021 {
    font-family: montserrat;
    display: inline-block;
    font-weight: 500;
    font-size: 18px;
    position: relative;
    left: 144px;
    color: white;
  }
  &_softex {
    position: relative;
    right: 121px;

    p {
      display: inline-block;
      display: inline-block;
      font-family: montserrat;
      // margin-left: 68px;
      font-weight: 300;
      font-size: 18px;
      color: white;
    }
    img {
      margin-left: 28px;
      cursor: pointer;
    }
  }
}
@media screen and(max-width:700px) {
  .footer {
    height: 84px;
    flex-direction: column;
    justify-content: center;
    &_2021 {
      left: 0;
      margin-bottom: 10px;
      // border: 1px solid #000;
      right: 0;
    }
    &_softex {
      right: 0;
      // border: 1px solid #000;
    }
  }
}
