@font-face {
  font-family: 'Moniqa';
  src: local('Moniqa'), url('./font/Moniqa-SemiBoldParagraph.ttf') format('truetype');
}
@font-face {
  font-family: 'Lunchbox';
  src: local('Lunchbox'), url('./font/Lunchbox.ttf') format('opentype');
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: white;
  // padding: 2px;
}
::-webkit-scrollbar-thumb {
  background: #6d35a2;
  border-radius: 5px;
  border: 2px solid white;
}
html,
*,
body {
  scroll-behavior: smooth;
  // overflow: hidden;
}
.App {
  // font-family: 'Moniqa';
  overflow-y: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .footer {
    margin-top: auto;
    // border: 1px solid #000;
  }

  // &_container {
  //   max-width: 1101px;
  //   padding: 20px;
  //   border: 1px solid #000;
  //   margin: 35px auto;
  //   position: relative;
  // }
}
// @media screen and(max-width:375px) {
//   .App {
//     .scrolltotop {
//       // left: 0;
//       // right: 0;
//       margin: 0 auto;
//     }
//   }
// }

// .wrapper {
//   max-width: 700px;
//   margin: 64px auto;
//   padding: 0 1rem;
//   border: 1px solid #000;
// }

.notification__content {
  font-family: 'Montserrat';
}
