@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
.fullCard {
  .container {
    max-width: 700px;
    // max-height: 1300px;
    border: 1px solid #000;
    padding: 0 60px;
    margin: 120px auto 48px;
    position: relative;
    // border: 1px solid #000;
    // top: 47.88px;
    h1 {
      font-family: Moniqa;
      // text-indent: 20px;
      position: absolute;
      font-style: normal;
      display: none;
      font-weight: 600;
      font-size: 54px;
      text-align: center;
      color: #6d35a2;
    }
    .prev {
      position: absolute;
      left: 10px;
      // border: 1px solid #000;
      top: -5px;
      //   top: 47.88px;
    }
    .newFull {
    }
    h2 {
      font-weight: 600;
      font-size: 32px;
      text-indent: 40px;

      color: #541a8a;
    }
    img {
      border-radius: 6px;
    }
    .timeDiv {
      display: flex;
      justify-content: space-between;
      margin: 15px 0;
      p {
        font-family: Montserrat;
        font-weight: normal;
        font-size: 10px;
        color: #541a8a;
      }
    }
    p {
      font-family: Montserrat;
      font-weight: normal;
      font-size: 17px;
      color: #000000;
    }
    .line {
      border: 1px dashed #959595;
    }
    .cardLink {
      display: flex;
      margin-top: 15px;
      font-family: 'Montserrat', sans-serif;
      justify-content: space-between;
      align-items: center;
      .energetika {
        display: flex;
        width: 250px;
        justify-content: space-around;
        // border: 1px solid #000;
      }
      .ulashish {
        align-items: center;
        display: flex;
        justify-content: space-around;
        width: 250px;
        // border: 1px solid #000;
      }
    }
  }
}
@media screen and(max-width:834px) {
  .fullCard {
    .container {
      h1 {
        display: block;
        top: -70px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
}
@media screen and(max-width:660px) {
  .fullCard {
    .container {
      .cardLink {
        // border: 1px solid #000;
        flex-direction: column;
        .energetika {
          margin-bottom: 15px;
        }
      }
    }
  }
}
@media screen and(max-width:550px) {
  .fullCard {
    .container {
      padding: 0 20px;
      h1 {
        font-size: 34px;
      }
      h2 {
        font-size: 24px;
      }
      .prev {
        top: -12px;
      }
    }
  }
}
