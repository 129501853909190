.news {
  width: 100%;
  // border: 4px solid #000;
  
  .wrapper {
    max-width: 700px;
    margin: 114px auto;
    padding: 0 1rem;
    // border: 1px solid #000;
    .datenews{
      display: flex;
      justify-content: space-between;
      // border: 1px solid #000;
      h1 {
        font-weight: 600;
        font-size: 54px;
        display: none;
        // line-height: 56px;
        // margin-top: 52px;
        // margin-left: 153px;
        color: #6D35A2;
      }
      .dateIncrease{
        width: 161px;
        height: 54px;
        background: rgba(109, 53, 162, 0.3);
        border: 1px solid rgba(109, 53, 162, 0.1);
        position: relative;
  
        box-shadow: 0px 38px 114px rgba(126, 8, 176, 0.08), 0px 4.75819px 14.2746px rgba(126, 8, 176, 0.04);
        border-radius: 30px 0px;
        img{
          position: absolute;
          top: 11px;
          left: 37px;
        }
        .filtr{
          font-family: Moniqa;
        font-style: normal;
        font-weight: 500;
        font-size: 27px;
        line-height: 28px;
        color: #541A8A;
        position: absolute;
        top: 13px;
        left: 87px;
        }
        .increaseDecrase{
          width: 252px;
          height: 122px;  
          top: 59px;
          background: #FFFFFF;
          border: 1px solid rgba(109, 53, 162, 0.1);
          position: absolute;
          box-shadow: 0px 38px 114px rgba(126, 8, 176, 0.08), 0px 4.75819px 14.2746px rgba(126, 8, 176, 0.04);
          border-radius: 0px 30px;
          // padding-left: 50px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          p{
            font-family: Moniqa;
            font-weight: 500;
            left:37px;
            position: relative;
            margin: 9px 0;
            cursor: pointer;
            display: inline-block;
            // border: 1px solid #000;
            font-size: 27px;

            color: #727272;
          }
        }
      }
    }
    .newsContainer {
      max-width: 700px;
      min-height: 1200px;
      // border: 1px solid #000;
      margin: 54px auto;
      display: grid;
      grid-column-gap: 20px;
      grid-row-gap: 40px;
      // grid-row-gap: 40px;
      grid-template-columns:  1fr 1fr 1fr 1fr 1fr 1fr;
      // grid-template-areas:
      // "a b"
      // "c d"
      // "f"
      // "b a"
      // ;
      .first {
        transition: 0.2s;
        // box-shadow: 0 0 10px 2px rgb(240, 235, 235);
        
          // width: 450px;
          // border: 1px solid #000;
          border-radius: 10px 10px 0 0;

          // height: 403px;
          overflow: hidden;
          // padding-bottom: 30px;
        &:nth-of-type(1){
          // grid-area: a;
          grid-column: 1/5;
        }
        &:nth-of-type(2){
          // grid-area: b;
          grid-column: 5/7 ;
  
        }
        &:nth-of-type(3){
          // grid-area: c;
          grid-column: 1/4;
        }
        &:nth-of-type(4){
          // grid-area: d;
          grid-column: 4/7;
        }
        &:nth-of-type(5){
          // grid-area: f;
          grid-column: 1/7;
          display: flex;
          // height: 300px;
          // border: 1px solid #000;
          .news_img{
              height: 100% !important;
              width: 360px !important; 
          }
  
        }
        &:nth-of-type(6){
          // grid-area: f;
          grid-column: 1/3;
  
        }
        &:nth-of-type(7){
          // grid-area: f;
          grid-column: 3/7;
  
        }
        cursor: pointer;
        // border: 1px solid #000;
        & > div:first-child img {
          width: 100%;
          border-radius: 10px 10px 0 0;
          // border: 1px solid #000;
          // height: 100%;
          object-fit: cover;
        }
        .reference {
          // height: 153px;
          overflow: hidden;
          // border: 1px solid #000;
          padding:20px 15px;
          .time {
            display: flex;
            height: 9px;
            padding: 4px 0;
            // border: 1px solid #000;
            justify-content: space-between;
            align-items: flex-end;
            .clock {
              font-family: Montserrat;
              font-style: normal;
              font-weight: normal;
              font-size: 10px;
              // margin-top: 15px;
              display: inline-block;
              color:  #541A8A;
              span {
                // position: relative;
                // top: 2px;
                // margin-right: 3px;
                //   margin-left: 15px;
              }
            }
            .eye {
              font-family: Montserrat;
              font-style: normal;
              font-weight: normal;
              font-size: 10px;
              line-height: 12px;
              position: relative;
              // border: 1px solid #000;
              margin-top: 15px;
  
              right: 15px;
              color:  #541A8A;
              // float: right;
              display: inline-block;
              span {
                position: relative;
                top: 2px;
                margin-right: 3px;
                margin-left: 15px;
              }
            }
          }
          h3 {
            font-weight: 600;
            font-size: 17px;
            line-height: 18px;
            // padding: 0px 15px;
            margin: 0%;
  
            color:  #541A8A;
          }
          p {
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 12px;
  
            color: #646464;
            // padding: 5px 15px;
          }
        }
      }
      .first:hover {
        box-shadow: 0px -7px 60px rgba(0, 0, 0, 0.08),
          0px -1.56354px 13.4018px rgba(0, 0, 0, 0.0522314),
          0px -0.465507px 3.99006px rgba(0, 0, 0, 0.0661368);
          border-radius: 10px 10px 0 0;
        

          transform: scale(1.05);
      }
    }
  }

}
@media screen and(max-width:834px) {
  .news{
    .wrapper{
      .datenews{
        h1{
          display: block;
        }
      }
    }
  }
}
@media screen and(max-width:640px) {
  .news{
    .wrapper{
      .newsContainer{
        display: block;
        padding: 10px;
        // border: 1px solid #000;
        margin: 0px auto;
        .first{
          margin: 20px 0;
          border-radius: 10px 10px 0 0;
        box-shadow: 0 0 10px 2px rgb(240, 235, 235);

          .reference{
            height: unset;
          }
        }
        .first:nth-child(5){
          display: block;
          .news_img{
            width: 100% !important;
            // height: unset !important;
          }
          .reference{
            // height: 100px !important;
            // border: 1px solid #000;
          }
        }
      }
    }
  }
}
@media screen and(max-width:375px) {
  .news{
    .wrapper{
      margin: 60px auto;
      .datenews{
        h1{
          font-size: 36px;
        }
      }
      
    }
  }
}
