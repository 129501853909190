@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.mainCont {
  width: 100%;
  .logo {
    position: absolute;
    width: 161px;
    left: 0;
    right: 0;
    top: 0px;
    margin: 0 auto;
    z-index: 12;
    // border: 1px solid #000;
    background: #6d35a2;
    padding: 18px 30px 2.7rem;
    img {
      // animation: example 2s linear infinite normal;
      width: 161px;
    }
  }
  // @keyframes example {
  //   0% {
  //     transform: scale(1);
  //   }
  //   50% {
  //     transform: scale(1.2);
  //   }
  //   100% {
  //     transform: scale(1);
  //   }
  // }
}
.main {
  // max-width: 1366px;
  margin: 0 auto;
  min-height: 653px;
  position: relative;
  overflow-x: hidden;
  height: 700px;

  .logodiv {
    width: 224px;
    // top: -81px;
    // z-index: 11;
    position: absolute;
    right: 0;
    left: 0;
    height: 422px;
    background-color: #6d35a2;
    margin: 0 auto;
    // .logo {
    //   width: 161px;
    //   margin: 18px auto;
    //   display: block;
    // }
    .plasticYogurt {
      width: 520px;
      position: relative;
      bottom: -260px;
      left: -148px;
    }
  }
  .circlesCont {
    // z-index: 1;
    position: relative;
    width: 224px;
    // height: 100%;
    margin: 0 auto;
    .shortText {
      // position: relative;
      position: absolute;
      display: inline-block;
      left: -340px;
      top: 170px;
      h1 {
        font-weight: bold;
        font-size: 50px;
        font-family: lunchbox;
        line-height: 52px;
        color: #6d35a2;
      }
      p {
        font-family: 'Montserrat', sans-serif;
        font-weight: 300;
        font-size: 16px;
        color: #6d35a2;
        padding-top: 7px;
      }
    }
    .shortTextResponsive {
      display: none;
      position: absolute;
      left: -340px;
      top: 170px;
      h1 {
        font-weight: bold;
        font-size: 50px;
        font-family: lunchbox;
        line-height: 52px;
        color: #6d35a2;
      }
      p {
        font-family: 'Montserrat', sans-serif;
        font-weight: 300;
        font-size: 16px;
        color: #6d35a2;
        padding-top: 7px;
      }
    }
    .circleAllDiv {
      width: 200px;
      height: 200px;
      position: absolute;
      // border: 1px solid #000;
      left: 313px;
      z-index: 9;
      top: 153px;
      .circle {
        border: 1px solid #6d35a2;
        border-radius: 50%;
        // border: 1px solid #000;
        width: 188px;
        height: 188px;
        position: relative;
        display: flex;
        align-items: center;
        z-index: 2;
        justify-content: flex-end;

        .circleButton {
          width: 126px;
          height: 126px;
          margin-right: 4px;
          background-color: #6d35a2;
          border-radius: 50%;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          p {
            font-family: lunchbox;
            font-weight: bold;
            font-size: 20px;
            color: white;
          }
        }
        .circleMini {
          width: 38px;
          height: 38px;
          border-radius: 50%;
          background-color: #6d35a2;
          position: absolute;
          bottom: 16px;
          right: -25px;
        }
        .circleAnimation {
          position: absolute;
          // border: 1px solid #000;
          z-index: 1;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          animation: aylanish 3s linear infinite normal;
        }
        @keyframes aylanish {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
    .ellipse {
      width: 726px;
      height: 726px;
      position: absolute;
      // border: 1px solid #000;
      // z-index: 9;
      left: 354px;
      top: -313px;
      // border: 1px solid #000;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(109, 53, 162, 0.05);
      .ellipseIn {
        width: 586px;
        height: 586px;

        background-color: white;
        border-radius: 50%;
      }
    }
    .ellipseMini {
      width: 94px;
      height: 94px;
      border-radius: 50%;
      background-color: rgba(109, 53, 162, 0.05);
      position: absolute;
      left: 365px + 224px;
      top: 425px;
    }
    .ellipseMini1 {
      width: 160px;
      // border: 1px solid #000;
      height: 160px;
      border-radius: 50%;
      background-color: rgba(109, 53, 162, 0.05);
      position: absolute;
      left: -604px;
      // top: -111px;
      top: -30px;
      // z-index: 20;
    }
    .wave {
      width: 204px;
      position: absolute;
      left: -294px;
      top: 290px;
      z-index: -1;
    }
  }
}
// @media screen and(max-width:960px) {
//   .mainCont {
//     .main {
//       .circlesCont {
//         .shortText {
//           left: -280px;
//         }
//       }
//     }
//   }
// }
@media screen and(max-width:919px) {
  .mainCont {
    .main {
      min-height: 600px;
      .logodiv {
        // border: 1px solid #000;
        height: 460px;
        .plasticYogurt {
          bottom: -290px;
        }
      }
      .circlesCont {
        .shortText {
          display: none;
        }
        .shortTextResponsive {
          left: -250px;
          display: block;
          h1 {
            font-size: 40px;
          }
        }
        .circleAllDiv {
          left: 16px;
          // border: 1px solid #000;
          top: 216px;
          z-index: 12;
          .circle {
            width: 156px;
            height: 156px;
            border: 1px solid white;
            .circleButton {
              width: 105px;
              height: 104px;
              background-color: white;
              p {
                color: #6d35a2;
              }
            }
            .circleMini {
              display: none;
            }
          }
        }
      }
    }
  }
}
@media screen and(max-width:745px) {
  .mainCont {
    overflow: hidden;
    .logo {
      width: 161px;
    }
    .main {
      // overflow: hidden;
      // border: 1px solid #000;
      height: 800px;
      .logodiv {
        width: 224px;
        height: 560px;
        // border: 1px solid #000;
        .plasticYogurt {
          width: 520px;
          bottom: -370px;
          left: -150px;
        }
      }
      .circlesCont {
        .shortTextResponsive {
          left: 32px;
          width: 160px;
          top: 200px;
          display: block;
          z-index: 5;
          // border: 1px solid #000;
          h1 {
            color: white;
            font-size: 24px;
            line-height: 27px;
            text-align: center;
          }
          p {
            color: white;
            text-align: center;
            line-height: 15px;
          }
        }
        .circleAllDiv {
          left: 45px;
          top: 360px;
          .circle {
            width: 130px;
            height: 130px;
            .circleButton {
              width: 87px;
              height: 86px;
            }
          }
        }
      }
    }
  }
}
@media screen and(max-width:425px) {
  .mainCont {
    overflow: hidden;
    .logo {
      width: 99px;
      img {
        width: 99px;
      }
    }
    .main {
      // overflow: hidden;
      .logodiv {
        width: 160px;
        height: 560px;
        .plasticYogurt {
          width: 360px;
          bottom: -420px;
          left: -100px;
        }
      }
      .circlesCont {
        .shortTextResponsive {
          left: 32px;
          width: 160px;
          display: block;
          z-index: 5;
          // border: 1px solid #000;
          h1 {
            color: white;
            font-size: 24px;
            line-height: 27px;
            text-align: center;
          }
          p {
            color: white;
            text-align: center;
            line-height: 15px;
          }
        }
        .circleAllDiv {
          left: 45px;
          top: 360px;
          .circle {
            width: 130px;
            height: 130px;
            .circleButton {
              width: 87px;
              height: 86px;
            }
          }
        }
      }
    }
  }
}
