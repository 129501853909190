body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Moniqa', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  scroll-behavior: smooth !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.styles_scroll-to-top__2A70v {
  width: 60px !important;
  height: 60px !important;
  background-color: transparent !important;
  border-radius: 50% !important;
  svg {
    display: none;
  }
  background-image: url('./components/image/go up.png');
}
