.shoping {
  width: 100%;
  height: 800px;
  // border: 1px solid rgb(216, 49, 49);
  position: relative;
  overflow: hidden;
  .shopingContainer {
    // top: 60px;
    max-width: 1366px;
    // border: 1px solid #000;
    padding: 25px;
    // padding: 0 166px;
    height: 30px;
    // border: 1px solid #000;
    margin: 60px auto 0;
    position: relative;
    h1 {
      font-family: LunchBox;
      font-weight: bold;
      font-size: 54px;
      // transform: translateX(40%);
      position: absolute;
      left: 10%;
      color: #6d35a2;
    }
    .shopingMiniCircle {
      width: 94px;
      height: 94px;
      position: absolute;
      border-radius: 50%;
      left: 8%;
      top: -23px;
      background-color: rgba(109, 53, 162, 0.05);
    }
    .shopingBigCircle {
      width: 451px;
      height: 451px;
      // border: 1px solid #000;
      position: absolute;
      // left: 950px;
      right: 0px;
      transform: translateX(40%);
      border-radius: 50%;

      // background-color: rgba(109, 53, 162, 0.05);
      background-color: rgba(10, 5, 2, 0.05);
      .shopingWave {
        width: 204px;
        position: absolute;
        top: 156px;
        // opacity: 0.5;
        left: -24px;
      }
      .shopingWave1 {
        width: 204px;
        position: absolute;
        top: 245px;
        left: 109px;
      }
    }
    .shopingWave2 {
      width: 204px;
      position: absolute;
      top: 465px;
      left: 0px;
    }
  }
  .shopingMap {
    background: url('./../image/Mask Group.png');
    max-width: 1050px;
    position: relative;
    // border: 1px solid #000;
    // top: -80px;
    margin: 0 auto;
    // background-color: yellow;
    // width: 100px;
    // height: 519px;
    height: 50vw;
    // position: absolute;
    // border: 1px solid rgb(29, 191, 110);
    background-repeat: no-repeat;
    background-size: 75%;
    background-position: top;
    // margin: 30px auto;
    // z-index: 10;
    // height: 561px;
    .shopingMapContainer {
      max-width: 200px;
      height: 100%;
      margin: 0 auto;
      // border: 1px solid #000;
      p {
        // position: absolute;
        // top: 194px;
        // left: 385px;
        // border: 1px solid #000;
        position: relative;
        top: 150px;
        left: 28%;
        // right: 0;
        color: white;
        font-weight: 600;
        font-size: 35px;
        // line-height: 36px;
        //   letter-spacing: 3px;
      }
      .shoppingMapLocation {
        width: 50px;
        height: 50px;
        position: relative;
        top: 200px;
        left: 55%;
        // border: 1px solid #000;
        .shopingMapLocationImage {
          position: absolute;
          // border: 1px solid #000;
          // top: 45%;
          // left: 50%;
          // left: 455.28px;
          cursor: pointer;
        }
        .shopingMapDiller {
          position: absolute;
          // left: 48%;
          transition: 0.6s;
          transform: translateY(-100%);
          width: 220px;
          z-index: 5;
          height: 120px;
          background-color: white;
          box-shadow: 0px -22px 36px rgba(16, 104, 7, 0.0367295),
            0px -4.91399px 11.9074px rgba(16, 104, 7, 0.0499631),
            0px -1.46302px 5.89857px rgba(16, 104, 7, 0.12);
          border-radius: 2px;
          h3 {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            color: #6d35a2;
            position: relative;
            top: 20px;
            left: 30px;
          }
          h2 {
            font-family: Moniqa;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 31px;

            color: #4d1384;
            position: absolute;
            top: 37px;
            left: 30px;
          }
          h4 {
            position: absolute;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 19px;
            line-height: 23px;

            color: #4d1384;
            top: 73px;
            left: 30px;
          }
          .shopingMapDillerSpan {
            width: 20px;
            transform: rotate(45deg);
            height: 20px;
            background-color: white;
            position: absolute;
            left: 20px;
            z-index: 0;
            bottom: -5px;
          }
        }
      }
    }
  }
}

@media screen and(max-width:834px) {
  .shoping {
    height: 80vw;
    .shopingMap {
      height: 50vw;
      .shopingMapContainer {
        .shoppingMapLocation {
          // left: 52%;
          top: 35%;
          // top: 39%;
        }
      }
    }
  }
}
// @media screen and(max-width:780px) {
//   .shoping {
//     .shopingMap {
//       p {
//         left: 320px;
//         // border: 1px solid #000;
//       }
//       .shopingMapLocation {
//         left: 370px;
//       }
//       .shopingMapDiller {
//         left: 370px;
//       }
//     }
//   }
// }
@media screen and(max-width:690px) {
  .shoping {
    height: 90vw;
    .shopingContainer {
      h1 {
        font-size: 36px;
      }
      .shopingBigCircle {
        width: 341px;
        height: 341px;
      }
    }
    .shopingMap {
      .shopingMapContainer {
        p {
          top: 32%;
          font-size: 24px;
        }
        .shoppingMapLocation {
          left: 55%;
          .shopingMapLocationImage {
            width: 34px;
          }
          .shopingMapDiller {
            width: 150px;
            height: 81px;
            h3 {
              font-size: 10.2px;
              left: 15px;
              top: 10px;
            }
            h2 {
              font-size: 20.4px;
              left: 15px;
              top: 20px;
            }
            h4 {
              font-size: 13px;
              top: 45px;
              z-index: 2;
              left: 15px;
            }
          }
        }
      }
    }
  }
}
@media screen and(max-width:560px) {
  .shoping {
    .shopingMap {
      p {
        // left: 220px;
      }
      .shopingMapLocation {
        // left: 260px;
        top: 20px;
        // border: 1px solid #000;
      }
      // .shopingMapDiller {
      //   left: 260px;
      // }
    }
  }
}
@media screen and(max-width:500px) {
  .shoping {
    .shopingContainer {
      h1 {
        font-size: 24px;
      }
      .shopingBigCircle {
        width: 240px;
        height: 241px;
      }
    }
    .shopingMap {
      .shopingMapContainer {
        p {
          left: 34%;
        }
        .shoppingMapLocation {
          // top: 30%;
          left: 46%;
        }
      }
    }
  }
}
@media screen and(max-width:400px) {
  .shoping {
    .shopingContainer {
      h1 {
        font-size: 34px;
      }
    }
    .shopingMap {
      .shopingMapContainer {
        p {
          font-size: 17px;
          left: 38%;
          // top: 25%;
        }
      }
    }
  }
}
