@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

.company {
  padding: 120px 0;
  // border: 1px solid #000;
  background-color: #fbf9fc;
  .companyContainer {
    max-width: 906px;
    padding: 0 53px;
    margin: 0 auto;
    // border: 1px solid #000;
    h1 {
      position: relative;
      font-family: lunchbox;
      // left: 153px;
      display: inline-block;
      font-weight: 600;
      font-size: 54px;

      color: #6d35a2;
    }

    .text {
      padding: 30px 0;
      // border: 1px solid #000;
      font-family: 'Montserrat';
      p {
        max-width: 880px;
        // border: 1px solid #000;
        text-indent: 20px;
        letter-spacing: 1px;
        font-size: 16px;
        margin: 0 auto;
        // font-family: moniqa;
        color: #6d35a2;
        // margin-bottom: 30px;
      }
      h2 {
        // font-family: moniqa;
        color: #6d35a2;
        // line-height: 40px;
        text-indent: 20px;
        margin: 14px 0 6px;
      }
      ul {
        // border: 1px solid #000;
        letter-spacing: 1px;
        margin-left: 20px;
        color: #6d35a2;
      }
    }

    .company_static1 {
      width: 100%;
      // margin: 0px auto;
      // border: 1px solid #000;

      display: flex;
      justify-content: space-around;
      .staticTop {
        // border: 1px solid #000;
        width: 50%;
        display: flex;
        justify-content: space-around;
        .company_static {
          // border: 1px solid #000;
          width: 193px;
          height: 160px;
          position: relative;
          background-color: white;
          display: inline-block;
          border-radius: 3px;
          h2 {
            font-weight: 700;
            font-family: lunchbox;
            top: 22px;
            left: 18px;
            font-size: 24px;
            position: relative;
            display: inline-block;

            color: #6d35a2;
          }
        }
        h3 {
          font-family: montserrat;
          font-weight: bold;
          font-size: 48px;
          position: absolute;
          top: 88px;
          // left: 60px;
          left: 0;
          right: 0;
          text-align: center;
          margin: 0 auto;
          // border: 1px solid #000;
          color: #6d35a2;
        }
      }
      .staticBottom {
        // border: 1px solid #000;
        width: 50%;
        display: flex;
        justify-content: space-around;
        .company_static {
          // border: 1px solid #000;
          width: 193px;
          height: 160px;
          position: relative;
          background-color: white;
          display: inline-block;
          border-radius: 3px;
          h2 {
            font-weight: 700;
            font-family: lunchbox;
            top: 22px;
            left: 18px;
            font-size: 24px;
            position: relative;
            display: inline-block;

            color: #6d35a2;
          }
        }
        h3 {
          font-family: 'Montserrat';
          font-weight: bold;
          font-size: 48px;
          position: absolute;
          top: 88px;
          // left: 60px;
          left: 0;
          right: 0;
          // border: 1px solid #000;
          text-align: center;
          color: #6d35a2;
        }
      }
    }
  }
}
@media screen and(max-width:900px) {
  .company {
    .companyContainer {
      .company_static1 {
        display: block;
        .staticTop {
          margin: 0 auto;
          width: 70%;
        }
        .staticBottom {
          margin: 40px auto;
          width: 70%;
        }
      }
    }
  }
}

@media screen and(max-width:690px) {
  .company {
    .companyContainer {
      .company_static1 {
        // border: 1px solid #000;
        display: block;
        .staticTop {
          display: block;
          margin: 0 auto;
          max-width: 193px;
          .company_static {
            display: block;
            margin-bottom: 20px;
          }
        }
        .staticBottom {
          display: block;
          margin: 40px auto;
          width: 193px;
          .company_static {
            display: block;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

@media screen and(max-width:415px) {
  h2 {
    font-size: 20px;
  }
  .company {
    padding: 35px 0;
    .companyContainer {
      h1 {
        font-size: 34px;
      }
      .company_static1 {
        // display: none;
      }
    }
  }
}
